<template>
    <div class="dn-accordion">
        <details
            v-for="(item, index) in items"
            :key="index"
            ref="accordionwrapper"
            class="dn-accordion__item"
            :open="visibleRowIndexes?.indexOf(index) !== -1"
        >
            <summary
                :id="item?.prettyID ?? `${componentId}-button-${index}`"
                :aria-controls="item?.prettyID ? `${item?.prettyID}-content` : `${componentId}-content-${index}`"
                :aria-expanded="visibleRowIndexes?.indexOf(index) !== -1"
                class="dn-accordion__button"
                role="button"
                @click.prevent="toggleRow(index)"
            >
                <div class="dn-accordion__button-grid">
                    <slot
                        name="title"
                        :item="item"
                        :open="visibleRowIndexes?.indexOf(index) !== -1"
                    >
                        {{ item.title }}
                    </slot>

                    <div class="dn-accordion__icon">
                        <slot
                            name="icon"
                            :item="item"
                            :open="visibleRowIndexes?.indexOf(index) !== -1"
                        >
                            ▼
                        </slot>
                    </div>
                </div>
            </summary>
            <div
                :id="item?.prettyID ? `${item?.prettyID}-content` : `${componentId}-content-${index}`"
                :aria-labelledby="item?.prettyID ?? `${componentId}-button-${index}`"
                class="dn-accordion__content"
            >
                <slot
                    :item="item"
                    :open="visibleRowIndexes?.indexOf(index) !== -1"
                >
                    {{ item.content }}
                </slot>
            </div>
        </details>
    </div>
</template>

<script>
export default {
    name: 'DnAccordion'
};
</script>

<script setup>
import { nextTick, ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

let uuid = 0;

const props = defineProps({
    openIndexes: {
        type: Array,
        required: false,
        default: () => []
    },

    items: {
        type: Array,
        required: true,
        default: () => []
    },

    autoclose: {
        type: Boolean,
        required: false,
        default: true
    },

    scrollIntoView: {
        type: Boolean,
        required: false,
        default: true
    },

    scrollSmooth: {
        type: Boolean,
        required: false,
        default: true
    },

    scrollOffset: {
        type: Number,
        required: false,
        default: 0
    }
});

const route = useRouter();

const componentId = ref(null);
const visibleRowIndexes = ref([...props.openIndexes]);

const accordionwrapper = ref(null);

const toggleRow = async(index) => {
    const arrayIndex = visibleRowIndexes?.value?.indexOf(index);

    if (arrayIndex === -1) {
        if (props.autoclose) {
            visibleRowIndexes.value = [];
        }
        visibleRowIndexes.value.push(index);

        if (props.scrollIntoView && accordionwrapper?.value?.[index]?.scrollIntoView) {
            await nextTick();

            scrollWithOffset(accordionwrapper.value[index]);
        }
    } else {
        visibleRowIndexes.value.splice(arrayIndex, 1);
    }
};

const scrollWithOffset = (element) => {
    // Get the y coordinate of the scroll destination
    const yCoordinate = element.getBoundingClientRect().top + window.scrollY;

    // Check if this.scrollSmooth is true, if so, scroll smoothly, otherwise scroll instantly
    const behavior = props.scrollSmooth ? 'smooth' : 'auto';

    // Scroll to the y coordinate with the optional offset
    window.scrollTo({ top: yCoordinate - props.scrollOffset, behavior });
};

const openActivelyHashedRow = () => {
    // find index of object in items array with prettyID matching the route hash
    const currentHash = route?.currentRoute?.value?.hash?.replace('#', ''); // Remove the hash symbol to match the prettyID
    const index = props?.items?.findIndex((item) => item?.prettyID?.replace('#', '') === currentHash); // Find the index of the item with the matching prettyID

    if (index > -1) { // If the index is found, open the row
        toggleRow(index);
    }
};

onMounted(async() => {
    componentId.value = `accordion_${uuid}`; // Make sure each accordion has it's own ID to prevent duplicate IDs
    uuid += 1; // Increment accordion counter
});

watch(() => route?.currentRoute?.value?.hash, (newValue) => {
    if (newValue) {
        openActivelyHashedRow();
    }
}, { immediate: true });
</script>

<style lang="less" src="./accordion.less"></style>
